import { useContext } from 'react';
import IconFlash from '../icons/IconFlash';
import pmContext from './PMContext';

const PMcreditsInfo = () => {
  const pmState = useContext(pmContext);
  const userCredits= pmState.userCredits;
  
  return (
    <>
    <div className="PM_box_left">
    
      {/* <div className="moreCreditTime">More credits in: 
        <span className='creditTimeCount'>{userCredits.credit_details?.credits_coming_by}
        <span className='upComingCredit'>{userCredits.credit_details?.credits_count_coming}</span>
        </span>
      </div> */}
    </div>
    <div className="credit_and_Icon">
      <div className="pm_credit">{userCredits.current_credits}</div>
        <IconFlash width="45" height="60" />
    </div>
    </>
  )
  
}

export default PMcreditsInfo;
