import { AccordionHeader, AccordionItem } from "react-headless-accordion";
import MRAKabob from "./General/MRKabob";
import coversationContext from "../../context/conversationContext";
import { useContext } from "react";
import ProfileMenuContext from "../profile-menu/PMContext";
import MainRegionContext from "./General/MRContext";

const WelcomeMessage = () => {
  const conversation = useContext(coversationContext);
  const mrstate = useContext(MainRegionContext);
  const pmState = useContext(ProfileMenuContext);
    return (
        <span className="welcome-msg-accordian">
            <AccordionItem >
                <div className={` acc_header_wrapper MRCprompt_wrap modelIconWrap welcome-msg`}>
                    {<>
                        <AccordionHeader as='div' className="titleWrap text" >
                            <div className="acco_col "  >

                                <div className="">
                                    <span className={`accordion-title `}>
                                        <div className="welcome-container">
                                        <div className="GptleftIcon">
                                        {conversation?.aiModelImage ?                            
                                            <div className="modelIcon modelIcon-welcome-msg" >
                                                <img src={conversation?.aiModelImage} alt="aiIcon" />
                                            </div> : ""}
                                        </div>{!mrstate.fileError ? <>
                                            Welcome <span className="user-name-welcome">{pmState?.userCredits?.user_name} </span>
                                            <br />
                                            <br />
                                            I'm Atticus, an AI-powered Agent that specializes in answering questions about your symptoms, identifying probable diagnoses, suggesting treatment options, and other personal health related inquiries. To get started, please upload a medical record or tell me about your health.
                                            </> : <> {mrstate.fileError} </>}
                                        </div>
                                    </span>
                                </div>
                            </div>

                        </AccordionHeader>
                        {/* {<MRAKabob/>} */}

                    </>}

                </div>
            </AccordionItem>
        </span>
    );
}

export default WelcomeMessage;