import { useContext } from 'react';
import pmContext from './PMContext';

const PMcreditsInfo = () => {
  const pmState = useContext(pmContext);
  const userCredits = pmState.userCredits;

  // Function to separate the strong text and filter out " - " from the rest
  const renderHTMLString = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const strongText = doc.querySelector('strong')?.innerHTML;
    let remainingText = doc.body.textContent.replace(strongText, '');
    remainingText = remainingText.replace(' - ', ''); // Removing " - "

    return (
      <div>
        <strong>{strongText}</strong>
        <span>{remainingText}</span>
      </div>
    );
  };

  return (
    <div className="plan_name">
      {renderHTMLString(userCredits.plan_name)}
    </div>
  );
}

export default PMcreditsInfo;
