import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheck } from '@fortawesome/free-solid-svg-icons';
import pmContext from './PMContext';
import MainRegionContext from '../main-region/General/MRContext';
import IconLogout from '../icons/IconLogout';
const ProfileLinks = () => {
  const pmState = useContext(pmContext);
  const MainRegionState = useContext(MainRegionContext);

  if (!pmState.PMLinks) {
    return <span>no links</span>;
  } else {
    const showMRSubscription = () => {
      MainRegionState.setMRSubscription(true);
    };

    return (
      <ul className="text-end">
        {pmState.PMLinks.data.map(data => {
          // using faicons since I dont have access to figma yet
          let icon;
          if (data.redirect_url.includes('contact')) {
            icon = <FontAwesomeIcon icon={faEnvelope} className="icon-space" />;
          } else if (data.redirect_url.includes('subscriptions')) {
            icon = <FontAwesomeIcon icon={faCheck} className="icon-space" />;
          }

          return (
            <li key={data.name} className={`profile-btn ${data.name === 'Subscriptions' ? 'profile-style' : ''}`}>
              <a href={data.redirect_url} rel="noreferrer" target={data.new_tab ? "_blank" : "_self"} className='temp'>
                <span >{data.name}</span>
                <img src={data.icon} alt={data.name} className="icon-space" />
              </a>
            </li>

          );
        })}
      </ul>
    );
  }
};

export default ProfileLinks;
