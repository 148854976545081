
const IconFeedRobot = (props) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height} 
            viewBox="0 0 22 21" 
            fill={props.fill}
            style={{margin: props.margin}}    
            >
                <path d="M18.9501 12.1015H17.9417V11.0932C17.9417 10.1665 17.1878 9.41263 16.2611 9.41263H14.5806V8.40429C14.5806 8.21875 14.43 8.06817 14.2445 8.06817H11.5555V7.34822C12.1337 7.19797 12.5639 6.67599 12.5639 6.05149C12.5639 5.31002 11.9609 4.70703 11.2194 4.70703C10.478 4.70703 9.87498 5.31002 9.87498 6.05149C9.87498 6.67599 10.3052 7.19797 10.8833 7.34822V8.06817H8.19441C8.00887 8.06817 7.85829 8.21875 7.85829 8.40429V9.41263H6.17772C5.25106 9.41263 4.49715 10.1665 4.49715 11.0932V12.1015H3.48881C2.93288 12.1015 2.48047 12.5539 2.48047 13.1099V17.1432C2.48047 17.6992 2.93288 18.1516 3.48881 18.1516H4.49715V19.1599C4.49715 20.0866 5.25106 20.8405 6.17772 20.8405H16.2611C17.1878 20.8405 17.9417 20.0866 17.9417 19.1599V18.1516H18.9501C19.506 18.1516 19.9584 17.6992 19.9584 17.1432V13.1099C19.9584 12.5539 19.506 12.1015 18.9501 12.1015ZM10.5472 6.05149C10.5472 5.68075 10.8487 5.37926 11.2194 5.37926C11.5902 5.37926 11.8917 5.68075 11.8917 6.05149C11.8917 6.42222 11.5902 6.72372 11.2194 6.72372C10.8487 6.72372 10.5472 6.42222 10.5472 6.05149ZM3.48881 17.4794C3.30361 17.4794 3.1527 17.3284 3.1527 17.1432V13.1099C3.1527 12.9247 3.30361 12.7738 3.48881 12.7738H4.49715V17.4794H3.48881ZM8.53052 8.7404H13.9083V9.41263H8.53052V8.7404ZM17.2695 19.1599C17.2695 19.7159 16.8171 20.1683 16.2611 20.1683H6.17772C5.62179 20.1683 5.16938 19.7159 5.16938 19.1599V11.0932C5.16938 10.5373 5.62179 10.0849 6.17772 10.0849H16.2611C16.8171 10.0849 17.2695 10.5373 17.2695 11.0932V19.1599ZM19.2862 17.1432C19.2862 17.3284 19.1353 17.4794 18.9501 17.4794H17.9417V12.7738H18.9501C19.1353 12.7738 19.2862 12.9247 19.2862 13.1099V17.1432Z" fill={props.fill} />
                <path d="M14.2436 15.4609H8.19354C8.008 15.4609 7.85742 15.6115 7.85742 15.7971V16.1332C7.85742 17.9865 9.36523 19.4943 11.2186 19.4943C13.0719 19.4943 14.5797 17.9865 14.5797 16.1332V15.7971C14.5797 15.6115 14.4291 15.4609 14.2436 15.4609ZM11.2186 18.8221C9.73596 18.8221 8.52965 17.6158 8.52965 16.1332H13.9075C13.9075 17.6158 12.7012 18.8221 11.2186 18.8221Z" fill={props.fill} />
                <path d="M9.20063 13.446C9.20063 12.7046 8.59764 12.1016 7.85617 12.1016C7.11471 12.1016 6.51172 12.7046 6.51172 13.446C6.51172 14.1875 7.11471 14.7905 7.85617 14.7905C8.59764 14.7905 9.20063 14.1875 9.20063 13.446ZM7.18395 13.446C7.18395 13.0753 7.48544 12.7738 7.85617 12.7738C8.22691 12.7738 8.5284 13.0753 8.5284 13.446C8.5284 13.8168 8.22691 14.1182 7.85617 14.1182C7.48544 14.1182 7.18395 13.8168 7.18395 13.446Z" fill={props.fill} />
                <path d="M14.5788 12.1016C13.8374 12.1016 13.2344 12.7046 13.2344 13.446C13.2344 14.1875 13.8374 14.7905 14.5788 14.7905C15.3203 14.7905 15.9233 14.1875 15.9233 13.446C15.9233 12.7046 15.3203 12.1016 14.5788 12.1016ZM14.5788 14.1182C14.2081 14.1182 13.9066 13.8168 13.9066 13.446C13.9066 13.0753 14.2081 12.7738 14.5788 12.7738C14.9496 12.7738 15.2511 13.0753 15.2511 13.446C15.2511 13.8168 14.9496 14.1182 14.5788 14.1182Z" fill={props.fill}/>
                <path d="M5.93902 7.29603C6.00322 7.36022 6.08926 7.39451 6.17665 7.39451C6.22001 7.39451 6.2637 7.3861 6.30538 7.36896C6.43075 7.31686 6.51277 7.19452 6.51277 7.05839V6.05005H8.52945C9.08538 6.05005 9.53779 5.59764 9.53779 5.04171V1.00834C9.53779 0.452409 9.08538 0 8.52945 0H1.80717C1.25124 0 0.798828 0.452409 0.798828 1.00834V5.04171C0.798828 5.59764 1.25124 6.05005 1.80717 6.05005H4.69304L5.93902 7.29603ZM1.47106 5.04171V1.00834C1.47106 0.823143 1.62197 0.672228 1.80717 0.672228H8.52945C8.71465 0.672228 8.86556 0.823143 8.86556 1.00834V5.04171C8.86556 5.22691 8.71465 5.37782 8.52945 5.37782H6.17665C5.99112 5.37782 5.84054 5.5284 5.84054 5.71394V6.24701L5.06983 5.4763C5.00664 5.41312 4.92127 5.37782 4.8322 5.37782H1.80717C1.62197 5.37782 1.47106 5.22724 1.47106 5.04171Z" fill={props.fill} />
                <path d="M20.6291 1.34375H13.9068C13.3508 1.34375 12.8984 1.79616 12.8984 2.35209V6.38546C12.8984 6.94139 13.3508 7.3938 13.9068 7.3938H15.9235V8.40214C15.9235 8.53793 16.0055 8.66062 16.1308 8.71271C16.1725 8.72986 16.2162 8.73826 16.2596 8.73826C16.347 8.73826 16.433 8.70397 16.4972 8.63978L17.7432 7.3938H20.6291C21.185 7.3938 21.6374 6.94139 21.6374 6.38546V2.35209C21.6374 1.79616 21.185 1.34375 20.6291 1.34375ZM20.9652 6.38546C20.9652 6.57066 20.8143 6.72157 20.6291 6.72157H17.604C17.515 6.72157 17.4293 6.75687 17.3664 6.82006L16.5957 7.59076V7.05769C16.5957 6.87215 16.4451 6.72157 16.2596 6.72157H13.9068C13.7216 6.72157 13.5707 6.57066 13.5707 6.38546V2.35209C13.5707 2.16689 13.7216 2.01598 13.9068 2.01598H20.6291C20.8143 2.01598 20.9652 2.16689 20.9652 2.35209V6.38546Z" fill={props.fill} />
                <path d="M2.14258 1.34375H8.19263V2.01598H2.14258V1.34375Z" fill={props.fill} />
                <path d="M2.14258 4.03516H8.19263V4.70738H2.14258V4.03516Z" fill={props.fill} />
                <path d="M2.81641 2.6875H7.522V3.35973H2.81641V2.6875Z" fill={props.fill} />
                <path d="M14.2441 2.6875H20.2942V3.35973H14.2441V2.6875Z" fill={props.fill} />
                <path d="M14.2441 5.37891H20.2942V6.05113H14.2441V5.37891Z" fill={props.fill} />
                <path d="M14.916 4.03516H19.6216V4.70738H14.916V4.03516Z" fill={props.fill} />
            </svg>
        </>
    );
};

export default IconFeedRobot;