
const IconFlash = (props) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" 
            width={props.width} 
            height={props.height} 
            viewBox="0 0 51 71" fill="none">
                <path d="M19.0945 70.4562L50.7641 23.5336C51.4811 22.4712 50.4643 21.1639 49.0495 21.3295L27.9837 23.7933L33.017 1.05502C33.1867 0.288482 32.0382 -0.150739 31.4907 0.471181L0.352542 35.8394C-0.554466 36.8695 0.405943 38.3342 1.89956 38.199L25.1245 36.0953L17.5106 69.9454C17.327 70.7618 18.6091 71.1754 19.0945 70.4562Z" fill="#495FAB" />
                <path d="M9.2387 34.4367C8.70614 34.4756 8.35243 33.9442 8.63552 33.5307L29.9524 2.39841C29.6352 2.16936 29.136 2.15462 28.8337 2.5189L0.322796 36.871C-0.507683 37.8717 0.371692 39.2942 1.73928 39.1629L23.0046 37.1196L16.0332 69.9972C15.9392 70.4407 16.2669 70.7527 16.65 70.8274L28.5961 36.3229C29.1561 34.7056 27.7433 33.0853 25.891 33.2207L9.2387 34.4367Z" fill="#5DCDF5" />
            </svg>
        </>
    );
};

export default IconFlash;