import { useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import Offcanvas from 'react-bootstrap/Offcanvas';
import PMCreditInfo from './PMCreditsInfo';
import ProfileLinks from './PMLinks';
import PMLogout from './PMLogout';
import PMMembershipstatus from './PMMembershipStatus';
import PMMenuTrigger from './PMMenuTrigger';

import pmContext from './PMContext';




function PMMenu() {
  const auth = useAuthUser();
  const pmState = useContext(pmContext);

  const [show, setShow] = useState(false);
  
  useEffect(() => {
    if (auth()){
      pmState?.setupdateUserCredit(true);
    }
  },[])

  const handleClose = () => {
    setShow(false);
    if (auth()){
      pmState?.setupdateUserCredit(true);
    }
  }
  const handleShow = () => {
    setShow(true);
  }

  return auth() && (
    <>
      {pmState.userCredits && <PMMenuTrigger handleShowPMMenu={handleShow} creditsInfo={pmState.userCredits} />}
      <Offcanvas placement='end' show={show} onHide={handleClose} className="overlay_menu_wrapper font-white">
        <Offcanvas.Header className="PM_credit_wrapp">
          {pmState.userCredits && <PMCreditInfo creditsInfo={pmState.userCredits} />}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='overlay_menu_link_wrap'>
            <div className="pm-links">
              <ul className="text-end">
                <ProfileLinks />
              </ul>
              <PMMembershipstatus />
            </div>
          </div>
        </Offcanvas.Body>

        <div className="logout">
          <PMLogout />
        </div>
      </Offcanvas>
    </>
  );

}

export default PMMenu;